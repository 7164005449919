<template>
    <div class="viewport_default">
        <div class="viewport__video" ref="videoWrap">
            <div v-for="camera in getCamerasBySearch" :key="camera.id">
              <video-item :video="camera" v-if="getActiveCamera && getActiveCamera.id === camera.id"></video-item>
            </div>
        </div>
        <div class="viewport__wrapper">
            <div class="viewport__search">
                <search-input></search-input>
            </div>
            <div class="viewport__list" :style="'max-height: ' + getListHeight">
                <div class="viewport__item"
                     
                     :class="[getActiveCamera.id == camera.id ? 'viewport__item_selected' : '', getActiveCamera.id == camera.id && isClick ? 'viewport__item_click' : '']"
                     v-for="camera in getCamerasBySearch"
                     :key="camera.id"
                     @click="selectCamera(camera)"
                >
                    <p class="class-name__text" v-if="camera.fullClassName && camera.fullClassName.length > 0">{{camera.fullClassName}}</p>
                    <div class="separator" v-if="camera.fullClassName && camera.fullClassName.length > 0"></div>
                    <p>{{camera.name}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchInput from "../forms/SearchInput";
    import VideoItem from "../video/VideoItem";
    export default {
        name: "DefaultViewport",
        components: {VideoItem, SearchInput},
        data() {
            return {
                activeCamera: {},
                heightList: 0,
                isClick: false
            }
        },
        computed: {
            getCamerasBySearch() {
                if (this.$store.getters.getSearch.length) {
                    const search = this.$store.getters.getSearch.toLowerCase()
                    return this.$store.getters.getCameras
                        .filter(camera => camera.name.toLowerCase().includes(search))
                }
                return this.$store.getters.getCameras
            },
            getActiveCamera() {
                if (this.activeCamera.id) {
                    return this.activeCamera
                }
                else {
                    return this.$store.getters.getCameras[0]
                }
            },
            getListHeight() {
                if (this.$windowWidth > 992) {
                    return 100 + '%'
                }
                else if(this.$windowWidth > 580) {
                    return (this.$windowHeight - 200 - (5.6 * 56.25)) +'px'
                }
                else {
                    const videoH = (this.$windowWidth - 20) / 100 * 56.25
                    return this.$windowHeight - 50 - videoH - 36 - 20 - 40 + 'px'
                }
            }
        },
        methods: {
            selectCamera(camera) {
                this.isClick = true
                return this.activeCamera = camera
            },
            changeHeightList() {
                if (this.$windowWidth > 992) {
                    return this.heightList = 100 + '%'
                }
                else {
                    const windowH = window.innerHeight
                    const videoH = (this.$windowWidth - 20) / 100 * 56.25
                    return this.heightList = windowH - 66 - videoH - 36 - 20 - 40 + 'px'
                }
            }
        },
        watch: {
            $route() {
                this.activeCamera = {}
            },
            isClick() {
                setTimeout(() => this.isClick = false, 250)
            }
        },
        mounted() {
            setTimeout(() => {
                this.changeHeightList()
            }, 500)
        }
    }
</script>

<style scoped lang="scss">
    .viewport {
        &__video {
            position: relative;
            margin-bottom: 20px;
            margin-top: 50px;
            width: 100%;
            max-width: 560px;
            min-height: 395px;
            &::before {
                content: '';
                padding-top: 56.25%;
                position: relative;
                display: block;
                z-index: -1;
            }
            @include media(992){
                min-height: auto;
                margin: 0 auto 20px;
            }
        }
        &__wrapper {
            padding-left: 50px;
            padding-top: 50px;
            padding-right: 50px;
            @include media(992) {
                padding-top: 0;
            }
            @include media(768){
                padding: 0;
            }
        }
        &__list {
            overflow-y: auto;
            position: relative;
            max-width: 375px;
            margin: 20px auto 0;
            @media screen and (min-width: 992px) {
                max-height: calc(100vh - 250px - 82px - 20px) !important;
            }
        }
        &__item {
            height: 52px;
            cursor: pointer;
            border-bottom: 1px solid rgba(139, 138, 141, .20);
            font-family: Roboto, "sans-serif";
            font-size: 17px;
            letter-spacing: 0;
            line-height: 20px;
            color: #8B8A8D;
            display: flex;
            align-items: center;
            padding: 0 19px;
            transition: all .25s;
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
                @include media(992) {
                    background-color: transparent;
                }
            }
            &_selected {
                color: #FD5739;
                &:hover {
                    background-color: transparent;
                }
            }
            &_click {
                transition: all 0s;
                background-color: rgba(0, 0, 0, 0.05) !important;
            }

        }
    }
</style>