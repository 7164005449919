<template>
  <div class="viewport_grid">
    <div class="slider__wrapper">
      <div class="slider__slider">
        <div class="slider__arrow slider__arrow_left" v-if="getCamerasBySearch.length > 4" @click="prevSlide">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="20" transform="matrix(0 -1 -1 0 20 20)" fill="#8B8A8D" fill-opacity="0.2"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.2658 30.1775C23.3243 30.9387 24.8 30.1823 24.8 28.8785V11.0967C24.8 9.79771 23.3338 9.04014 22.2743 9.79174L9.82702 18.6217C8.93158 19.2569 8.92728 20.5847 9.81858 21.2257L22.2658 30.1775Z"
                  fill="white"/>
          </svg>
        </div>
        <div class="slider__arrow slider__arrow_right" v-if="getCamerasBySearch.length > 4" @click="nextSlide">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="20" transform="matrix(0 -1 -1 0 20 20)" fill="#8B8A8D" fill-opacity="0.2"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.2658 30.1775C23.3243 30.9387 24.8 30.1823 24.8 28.8785V11.0967C24.8 9.79771 23.3338 9.04014 22.2743 9.79174L9.82702 18.6217C8.93158 19.2569 8.92728 20.5847 9.81858 21.2257L22.2658 30.1775Z"
                  fill="white"/>
          </svg>
        </div>
        <slick ref="slider" class="slider" :options="slickOptions" v-if="getCamerasBySearch.length"
               @afterChange="handleAfterChange">
          <div class="slider__item" v-for="(video, index) in getCamerasBySearch"
               :key="video.id">
            <div class="slider__wrap" :style="{width: getSlideWidth + 'px'}">
              <video-item
                  v-if="index >= getActiveSlides.start && index < getActiveSlides.finish"
                  type="column"
                  :video="video"
              ></video-item>
            </div>
          </div>
        </slick>
      </div>

      <div class="slider__dots" v-if="getCamerasBySearch.length > 4">
        <div class="slider__item slider__item-dot" v-for="val in Math.ceil(getCamerasBySearch.length / 4)" :key="val">
          <div class="slider__line" @click="$refs.slider.goTo(val - 1)"
               :class="activeSlide === val - 1 ? 'active' : ''"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import VideoItem from "../video/VideoItem";

export default {
  name: "GridViewport",
  components: {
    VideoItem,
    Slick
  },
  data() {
    return {
      slickOptions: {
        rows: 2,
        infinite: true,
        slidesPerRow: 2,
        arrows: false,
        dots: false,
        useTransform: false,
        swipe: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
      activeSlide: 0,
    }
  },
  computed: {
    getSlideWidth() {
      if(this.$windowWidth > 1400) {
        return (1190 / 2) - 8
      }
      else if (this.$windowWidth > 992) {
        return ((this.$windowWidth * 0.85) / 2) - 8
      }
      else if(this.$windowWidth > 768) {
        return ((this.$windowWidth * 0.8) / 2) - 8
      }
      return ((this.$windowWidth) / 2) - 8
    },
    getCamerasBySearch() {
      if (this.$store.getters.getSearch.length) {
        const search = this.$store.getters.getSearch.toLowerCase()
        return this.$store.getters.getCameras
            .filter(camera => camera.name.toLowerCase().includes(search))
      }
      return this.$store.getters.getCameras
    },
    getActiveSlides() {
      if (this.activeSlide === 0) {
        return {
          start: 0,
          finish: 4
        }
      } else {
        return {
          start: this.activeSlide * 4,
          finish: this.activeSlide * 4 + 4
        }
      }
    },
  },
  methods: {
    handleAfterChange(event, slick, currentSlide) {
      this.activeSlide = currentSlide
    },
    prevSlide() {
      this.$refs.slider.prev();
    },
    nextSlide() {
      this.$refs.slider.next();
    }
  },
  watch: {
    getCamerasBySearch() {
      if (this.getCamerasBySearch.length && this.$refs.slider) {
        this.$refs.slider.destroy()
        this.$nextTick(function () {
          if (this.$refs.slider) {
            this.$refs.slider.create(this.slickOptions)
          }
        })
      }
    },
  },
  mounted() {
    this.$store.commit('changeSearch', '')
  }
}
</script>

<style scoped lang="scss">
.slider {
  &__wrapper {
    max-width: 100%;
    margin: 0 auto;
    //max-height: 100%;
    //overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    //height: calc(100vh - 132px);
    height: 100%;
    flex-direction: column;
    @media screen and (orientation: landscape) and (max-width: 992px) {
      //padding-top: 66px;
      height: 100vh;
      max-width: 80%;
    }
  }

  width: 100%;

  &__wrap {
    position: relative;
    outline: none;
    width: 100%;

    &::before {
      content: '';
      padding-top: 56.25%;
      display: block;
    }
  }
}

.slider {
  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    max-width: 200px;
    margin: 0 auto;
    @media screen and (orientation: landscape) {
      display: none;
    }
  }

  &__item {
    padding: 0 4px;
    outline: none;
    //min-width: 170px;
    width: 50%;
    //width: 32px;
    //margin: 0 auto;
    &-dot {
      padding: 0;
    }

    @include media(992) {
      vertical-align: top;
      padding: 2px;
    }
  }

  &__line {
    width: 24px;
    height: 4px;
    margin: 0 4px;
    background: #EBECEF;
    border-radius: 2.5px;
    cursor: pointer;

    &.active {
      background: #F54E5E;
    }
  }

  &__slider {
    width: 85%;
    margin: 0 auto;
    position: relative;
    @include media(992) {
      width: 100%;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .25s;
    cursor: pointer;

    circle {
      transition: all .25s;
    }

    &_left {
      left: -50px;
    }

    &_right {
      right: -50px;
      transform: translateY(-50%) rotate(180deg);
    }

    &:hover {
      circle {
        fill: #fd5739;
        fill-opacity: 1;
      }
    }

    @include media(992) {
      display: none;
    }
  }
}

@import '~slick-carousel/slick/slick.css';
</style>
