<template>
    <div class="viewport__wrapper">
        <main-sidebar v-if="$store.getters.getAccount.type === 2"></main-sidebar>
        <div :class="$store.getters.getAccount.type === 2 && $windowWidth > 992 ? 'sidebar_desk' : ''" v-if="($store.getters.getCameras.length && $store.getters.getAccount.type !== 2) || ($store.getters.getAccount.type === 2 && $route.query.account)" style="width: 100%;">
            <default-viewport class="viewport" :style="getTopPadding" v-if="getTypeView === 'default'"></default-viewport>
            <list-viewport class="viewport" :style="getTopPadding" v-else-if="getTypeView === 'list'"></list-viewport>
            <grid-viewport class="viewport" :style="getTopPadding" v-else-if="getTypeView === 'column'"></grid-viewport>
        </div>
    </div>
</template>

<script>
    import DefaultViewport from "../components/viewport/DefaultViewport";
    import ListViewport from "../components/viewport/ListViewport";
    import GridViewport from "../components/viewport/GridViewport";
    import MainSidebar from "../components/layouts/MainSidebar";
    export default {
        name: "Home",
        components: {MainSidebar, GridViewport, ListViewport, DefaultViewport},
        computed: {
            getTypeView() {
                return this.$store.getters.getTypeView
            },
            getQueryAccount() {
                return this.$route.query.account
            },
            getTopPadding() {
                if (this.getTypeView === 'list') {
                    return 'margin-top: 0px'
                }
                else if(this.getTypeView === 'column') {
                    return 'margin-top: 0px'
                }
                else {
                    return 'margin-top: 0'
                }
            }
        },
        methods: {
            changeTypeView() {
                if (this.getTypeView !== 'column') {
                    document.documentElement.classList.add('no-orient')
                }
                else {
                    document.documentElement.classList.remove('no-orient')
                }
            }
        },
        watch: {
            getTypeView() {
                console.log(123)
                this.changeTypeView()
            }
        },
        mounted() {
          window.scrollTo(0,0)
            this.changeTypeView()
            this.$store.commit('changeShowHeader', true)
        }
    }
</script>

<style scoped lang="scss">
    .viewport {
        height: 100vh;
        max-width: 1190px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 auto;
        box-sizing: border-box;
        &_default {
            //max-width: 560px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-direction: row-reverse;
            max-width: 100%;
            overflow: hidden;
            @include media(992){
                flex-direction: column;
                display: block;
            }
        }
        &_list {
            padding-right: 0;
            padding-left: 0;
            overflow-y: scroll;
        }
        &_grid {
            //display: flex;
            //justify-content: stretch;
            //align-items: center;
            max-width: 1400px;
            height: calc(100vh - 66px - 66px);
            padding-left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
            width: 100%;
            margin: 0 auto;
            //padding-top: 66px;
            padding-right: 0;
            @media screen and (orientation: landscape) and (max-width: 992px) {
                //padding-top: 66px;
                height: 100vh;
            }
        }
        &__wrapper {
            display: flex;
            width: 100%;
            align-items: flex-start;
        }
    }
    .sidebar_desk {
        padding-left: 220px;
    }
</style>
