<template>
    <div class="viewport_list" ref="viewport">
        <div ref="videoViewport">
            <div class="viewport__item" v-for="video in getCamerasBySearch" :key="video.id">
                <video-item :video="video" :frame="frame" :cameras="getCamerasBySearch"></video-item>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoItem from "../video/VideoItem";
    export default {
        name: "ListViewport",
        components: {VideoItem},
        data() {
            return {
                isScroll: true,
                frame: {
                    top: 0,
                    bottom: 0
                }
            }
        },
        computed: {
            getCamerasBySearch() {
                if (this.$store.getters.getSearch.length) {
                    const search = this.$store.getters.getSearch.toLowerCase()
                    return this.$store.getters.getCameras
                        .filter(camera => camera.name.toLowerCase().includes(search))
                }
                return this.$store.getters.getCameras
            },
        },
        methods: {
            changeFrame (e) {
                console.log(e)
                if (this.isScroll) {
                    this.isScroll = false
                    setTimeout(() => {
                        this.isScroll = true
                        if (e && e.target) {
                            console.log(this.$refs.viewport.scrollTop)
                            this.frame.top = Math.floor(this.$refs.viewport.scrollTop)
                            this.frame.bottom = this.frame.top + window.innerHeight
                        } else {
                            this.frame.top = 0
                            this.frame.bottom = window.innerHeight
                        }
                    }, 500)
                }
            }
        },
        mounted() {
            this.changeFrame()
            this.$refs.viewport.addEventListener('scroll', this.changeFrame)
        },
        beforeDestroy() {
            this.$refs.viewport.removeEventListener('scroll', this.changeFrame)
        }
    }
</script>

<style scoped lang="scss">
    .viewport {
        &__item {
            position: relative;
            max-width: 576px;
            margin: 0 auto 2px;
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                content: '';
                padding-top: 56.25%;
                display: block;
            }
        }
    }
</style>
