<template>
    <div class="sidebar" :class="$windowWidth < 992 && !$route.query.account ? 'sidebar_mobile' : ''" >
        <div class="sidebar__wrapper">
            <div class="sidebar__header">
                <header-avatar :is-pay="true" @changeMenu="showMenu = !showMenu"></header-avatar>
                <div class="sidebar__menu" :class="showMenu ? 'open' : ''">
                    <ul>
<!--                        <li>-->
<!--                            <router-link-->
<!--                                    :to="{ name: 'Payments' }"-->
<!--                                    :class="!$store.getters.getIsPay ? 'sidebar__pay' : ''"-->
<!--                            >-->
<!--                                <svg-->
<!--                                        width="14"-->
<!--                                        height="9"-->
<!--                                        viewBox="0 0 14 9"-->
<!--                                        fill="none"-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                >-->
<!--                                    <path-->
<!--                                            fill-rule="evenodd"-->
<!--                                            clip-rule="evenodd"-->
<!--                                            d="M14 1.43182V2.12216C14 2.19275 13.9388 2.25 13.8633 2.25H0.136719C0.0612227 2.25 0 2.19275 0 2.12216V1.43182C0 0.641045 0.685562 0 1.53125 0H12.4688C13.3144 0 14 0.641045 14 1.43182ZM0 3.19602V7.56818C0 8.35895 0.685562 9 1.53125 9H12.4688C13.3144 9 14 8.35895 14 7.56818V3.19602C14 3.12543 13.9388 3.06818 13.8633 3.06818H0.136719C0.0612227 3.06818 0 3.12543 0 3.19602ZM3.5 6.54545C3.5 6.77137 3.30411 6.95455 3.0625 6.95455H2.625C2.38339 6.95455 2.1875 6.77137 2.1875 6.54545V6.13636C2.1875 5.91044 2.38339 5.72727 2.625 5.72727H3.0625C3.30411 5.72727 3.5 5.91044 3.5 6.13636V6.54545Z"-->
<!--                                            fill="#B4B4B4"-->
<!--                                    />-->
<!--                                </svg>-->
<!--                                <span>Управление тарифом</span>-->
<!--                            </router-link>-->
<!--                        </li>-->
                        <li>
                            <span @click="exit">Выход</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="sidebar__search">
                <img src="../../assets/icons/search.svg" >
                <input type="text" v-model="textSearch" placeholder="Поиск">
            </div>
        </div>
        <div class="sidebar__content">
            <div class="sidebar__list">
                <div class="sidebar__item"
                     v-for="account in getAccounts"
                     :key="account.id"
                     :class="{'sidebar__item_selected' : activeAccount == account.id}"
                     @click="changeAccount(account.id)"
                >
                    <div class="sidebar__img">
                        <img :src="account.avatar">
                    </div>
                    <div class="sidebar__info">
                        <h3>{{account.name}}</h3>
                        <p>{{account.address}}</p>
                    </div>
                    <div class="sidebar__count">
                        <p><img src="../../assets/icons/camera_icon.svg">{{account.camerasCount}}</p>
                    </div>
                    <div class="sidebar__marker">
                        <img src="../../assets/icons/marker.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import HeaderAvatar from "./HeaderAvatar";
    export default {
        name: "MainSidebar",
        components: {HeaderAvatar},
        data() {
            return {
                showMenu: false,
                textSearch: '',
                activeAccount: null
            }
        },
        computed: {
            getAccounts() {
                if (!this.textSearch.length) {
                    return this.$store.getters.getAccount.childUsers
                }
                else {
                    return this.$store.getters.getAccount.childUsers.filter(item => {
                        return item.name.indexOf(this.textSearch) !== -1
                    })
                }
            }
        },
        methods: {
            exit() {
                this.$store.dispatch("exitFromAccount");
                this.$router.push({ name: "Auth" });
            },
            changeAccount(id) {
                const account = _.find(this.$store.getters.getAccount.childUsers, item => {
                    return item.id === id
                })
                if (account) {
                    localStorage.setItem('subAccount', id)
                    this.$router.push({
                        path: '/',
                        query: {
                            account: id
                        }
                    })
                    this.activeAccount = id
                    this.$store.commit('changeCameras', account.cameras)
                }
            }
        },
        watch: {
            $route() {
                this.activeAccount = this.$route.query.account
            }
        },
        mounted() {
            console.log(localStorage.getItem('subAccount'))
            this.activeAccount = localStorage.getItem('subAccount')
        }
    }
</script>

<style scoped lang="scss">
    .sidebar {
        width: 220px;
        position: fixed;
        top: 0;
        height: 100vh;
        background-color: #fff;
        padding: 20px 0;
        @include media(992) {
            display: none;
            position: relative;
        }

        &_mobile {
            display: block;
            width: 100%;
            max-width: 360px;
            margin: 0 auto;
            z-index: 10000000;
            background: transparent;
        }


        &__header {
            position: relative;
            margin-left: 16px;
            z-index: 2;
        }
        &__menu {
            margin-top: 10px;
            position: absolute;
            top: 100%;
            left: 0;
            width: 191px;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            border-radius: 3px;
            padding-top: 18px;
            padding-left: 35px;
            padding-bottom: 35px;
            padding-right: 10px;
            display: none;
            @media screen and (max-width: 992px) {
                left: -150px;
            }
            ul {
                li {
                    position: relative;
                    margin-bottom: 11px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg {
                        position: absolute;
                        left: -20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            &.open {
                display: block;
            }
            span {
                font-size: em(14);
            }
            a,
            span {
                cursor: pointer;
                font-family: Roboto, "sans-serif";
                color: #0f0f0f;
                text-decoration: none;
                &.router-link-active {
                    color: #fd5739;
                }
            }
        }

        &__pay {
            svg {
                * {
                    fill: #fd5739;
                }
            }
        }

        &__search {
            max-width: 340px;
            width: 95%;
            height: 36px;
            background-color: rgba(174,174,174,0.20);
            border: 1px solid rgba(151,151,151,0.05);
            display: flex;
            align-items: center;
            border-radius: 8px;
            margin: 30px auto 0 ;
            @include media(992) {
                margin: 0;
                max-width: 280px;
                width: 100%;
            }
            img{
                margin-left: 18px;
            }
            input{
                margin-left: 15px;
                width: 273px;
                height: 36px;
                font-family: Roboto, "sans-serif";
                font-weight: 400;
                font-size: 13px;
                margin-top: 0px;
                line-height: 19px;
                letter-spacing: 0;
                color: #4a4a4a;
                border: 0;
                outline: 0;
                background-color: transparent;

                &::placeholder{
                    font-weight: 100;
                }
            }
        }

        &__content {
            padding: 0px 0px 0;
        }
        &__list {
            padding-top: 9px;
            @include media(992) {
                padding-top: 25px;
            }
        }
        &__item {
            margin-left: auto;
            margin-right: auto;
            border-bottom: 1px solid rgba(173, 173, 173, 0.28);
            width: 100%;
            height: 50px;
            display: flex;
            position: relative;
            //margin-bottom: 10px;
            padding-left: 11px;
            overflow: hidden;
            align-items: center;
            cursor: pointer;
            background: #fff;
            &_selected {
                background-color: #f9f9f9;
            }
            @include media(992) {
                margin-bottom: 10px;
                box-shadow: 0 2px 5px 0 rgb(53 53 53 / 26%);
                height: 80px;
                border-radius: 8px;
                padding-left: 0;
                align-items: flex-start;
            }
        }

        &__img {
            width: 28px;
            height: 28px;
            img {
                width: 28px;
                height: 28px;
                vertical-align: middle;
                object-fit: cover;
                @include media(992) {
                    width: 100px;
                    height: 80px;
                }
            }
            @include media(992) {
                width: 100px;
                height: 80px;
            }
        }
        &__info {
            margin-left: 11px;
            height: 28px;
            h3 {
                width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 11px;
                font-weight: 300;
                color: #2C2C2C;
                margin: 0;
                @include media(992) {
                    width: 178px;
                    color: rgba(0, 0, 0, 0.8);
                }
            }
            p {
                width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-family: Roboto, "sans-serif";
                font-size: 9px;
                letter-spacing: 0;
                line-height: 15px;
                font-weight: 400;
                color: #626262;
                margin-top: 3px;
                @include media(992) {
                    width: 178px;
                    font-size: 11px;
                    color: #9D9D9D;
                }
            }
            @include media(992) {
                padding-top: 11px;
            }
        }

        &__count {
            padding-left: 19px;
            img {
                margin-right: 5px;
            }
            p {
                font-family: Roboto, "sans-serif";
                font-size: 9px;
                letter-spacing: 0.36px;
                line-height: 10px;
                color: rgba(82, 82, 82, 0.9);
                display: flex;
            }
            @include media(992) {
                position: absolute;
                bottom: 15px;
                left: 95px;
            }
        }
        &__wrapper {
            @include media(992) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row-reverse;
            }
        }
        &__marker {
            display: none;
            @include media(992) {
                display: block;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
                img {
                    max-width: 14px;
                    height: auto;
                    width: auto;
                    vertical-align: middle;
                }
            }
        }
    }
</style>